import React, { useRef } from 'react'

import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import Slider, { Settings } from 'react-slick'
import tw from 'twin.macro'

type AutomaticSliderGallery = {
  title?: string
  localFile?: {
    childImageSharp?: {
      gatsbyImageData?: IGatsbyImageData
    }
  }
}

type AutomaticSliderProps = {
  items: AutomaticSliderGallery[]
}

const AutomaticSlider = ({ items }: AutomaticSliderProps) => {
  const sliderRef = useRef<Slider>()

  const settings: Settings = {
    arrows: false,
    autoplay: true,
    autoplaySpeed: 4500,
    className: 'automatic-slider',
    draggable: false,
    fade: true,
    infinite: true,
    slidesToShow: 1,
    speed: 3000,
    swipeToSlide: false,
  }

  return (
    <Slider
      ref={sliderRef as React.LegacyRef<Slider> | undefined}
      {...settings}
    >
      {items?.map((item: AutomaticSliderGallery, index) => {
        return (
          <div key={index}>
            <GatsbyImage
              alt={`${item.title}`}
              css={tw`max-h-full w-full`}
              imgStyle={{
                objectFit: 'cover',
                objectPosition: 'center',
              }}
              image={
                item.localFile?.childImageSharp
                  ?.gatsbyImageData as IGatsbyImageData
              }
            />
          </div>
        )
      })}
    </Slider>
  )
}

export default AutomaticSlider
