import React from 'react'

import { BLOCKS } from '@contentful/rich-text-types'
import { Text as ContentfulText } from '@contentful/rich-text-types/dist/types/types'
import { motion } from 'framer-motion'
import { navigate } from 'gatsby'
import { IGatsbyImageData } from 'gatsby-plugin-image'
import {
  ContentfulRichTextGatsbyReference,
  renderRichText,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text'
import tw from 'twin.macro'

import { useHeroContent } from 'content-queries/mx/home-hero'
import fireEvent from 'context/tracking/events'
import AutomaticSlider from 'mx/components/automatic-slider'
import { MxButton as Button } from 'mx/components/section-components'
import { Section } from 'mx/section'

interface HeroProps {
  data?: {
    actionText: string
    actionUrl: string
    description: string
    markdown: RenderRichTextData<ContentfulRichTextGatsbyReference>
    photos?: {
      title?: string
      file?: {
        url?: string
      }
      localFile?: {
        childImageSharp?: {
          gatsbyImageData?: IGatsbyImageData
        }
      }
    }[]
    slotTitle: string
  }
}

const variants = {
  visible: {
    opacity: 1,
    y: 0,
    transition: { ease: 'easeOut', delay: 0.25, duration: 0.75 },
  },
  hidden: { opacity: 0, y: 75 },
}

// Laptop: displays from 768px
const Hero = ({ data }: HeroProps) => {
  const { data: defaultData } = useHeroContent()
  const heroContent = data || defaultData?.[0]?.node

  return (
    <Section
      padding="none"
      css={[tw`hidden`, tw`md:block`]}
      tw="md:(mb-8 pt-0 w-full max-w-full)"
    >
      <motion.div
        initial="hidden"
        animate="visible"
        variants={variants}
        tw="flex w-full h-full min-h-[80vh]"
      >
        <div tw="absolute inset-0 w-full h-full">
          <AutomaticSlider items={heroContent.photos} />
        </div>
        <div tw="flex flex-col w-8/12 z-10 justify-end items-baseline text-left text-white pl-20 py-20 bg-gradient-to-l from-transparent to-black">
          <h1 tw="max-w-lg text-4xl font-thin leading-tighter mb-8">
            {heroContent.slotTitle}
          </h1>
          {heroContent.markdown &&
            renderRichText(heroContent.markdown, {
              renderNode: {
                [BLOCKS.PARAGRAPH]: (node, children) => {
                  const { value: textValue } = node.content[0] as ContentfulText

                  if (node.nodeType === 'paragraph' && textValue) {
                    return (
                      <p tw="text-lg font-light text-white mb-12 leading-relaxed max-w-lg">
                        {children}
                      </p>
                    )
                  }
                },
              },
              renderText: (text) =>
                text
                  .split('\n')
                  .flatMap((text, i) => [i > 0 && <br key={i} />, text]),
            })}
          {heroContent.actionText && (
            <Button
              color="whiteHover"
              onClick={() => {
                fireEvent({
                  type: 'cta_clicked',
                  ctaStr: 'Try Form For Free // Hero Desktop',
                })
                navigate(heroContent.actionUrl)
              }}
            >
              {heroContent.actionText}
            </Button>
          )}
        </div>
      </motion.div>
    </Section>
  )
}

// Mobile: displays up to 768px
const MobileHero = ({ data }: HeroProps) => {
  const { data: defaultData } = useHeroContent()
  const heroContent = data || defaultData?.[0]?.node

  return (
    <Section tw="relative md:(hidden) overflow-x-hidden" padding="bottom">
      <div tw="flex items-start relative" style={{ height: '60vh' }}>
        <div
          tw="absolute w-screen overflow-hidden -z-1 bg-white"
          style={{ height: '60vh' }}
        >
          <AutomaticSlider items={heroContent.photos} />
        </div>
        <div className="triangle-hero" tw="absolute bottom-0 left-0 z-10" />
      </div>
      <div tw="bg-white pl-4 pt-4 relative">
        <div tw="mb-4">
          <h1 tw="font-thin text-black text-4xl leading-tight xs:(max-w-xl) sm:(max-w-none)">
            {heroContent.slotTitle}
          </h1>
        </div>
        {heroContent.markdown &&
          renderRichText(heroContent.markdown, {
            renderNode: {
              [BLOCKS.PARAGRAPH]: (node, children) => {
                const { value: textValue } = node.content[0] as ContentfulText

                if (node.nodeType === 'paragraph' && textValue) {
                  return (
                    <p tw="mb-8 w-72 leading-relaxed text-lg font-light text-gray-500">
                      {children}
                    </p>
                  )
                }
              },
            },
            renderText: (text) =>
              text
                .split('\n')
                .flatMap((text, i) => [i > 0 && <br key={i} />, text]),
          })}
        {heroContent.actionText && (
          <Button
            color="default"
            onClick={() => {
              fireEvent({
                type: 'cta_clicked',
                ctaStr: 'Try Form For Free // Hero Mobile',
              })
              navigate(heroContent.actionUrl)
            }}
          >
            {heroContent.actionText}
          </Button>
        )}
      </div>
    </Section>
  )
}

export { Hero, MobileHero }
