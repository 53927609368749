import { graphql, useStaticQuery } from 'gatsby'

export const useHeroContent = () => {
  const { content } = useStaticQuery(graphql`
    query {
      content: allContentfulMxSlot(
        filter: { contentful_id: { eq: "5bPuA7REiVveBaiBJwEXeG" } }
      ) {
        data: edges {
          node {
            slotTitle
            actionText
            actionUrl
            description
            photos {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            markdown {
              raw
            }
          }
        }
      }
    }
  `)
  return content
}

export default useHeroContent
